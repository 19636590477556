import React,{useState,useEffect} from 'react'

export default function TakeAction() {
    const schedulecall = ()=>{
        const url = 'https://calendly.com/walter-gomez-kc/priority-investor-zoom-meeting-with-the-found-clone-1';
        window.open(url, '_blank');
    }
    const [isMobile, setIsMobile] = useState(false);

    // Function to check screen width
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
  
    // Check the screen size on initial render and add event listener for resize
    useEffect(() => {
      handleResize(); // Check on initial render
      window.addEventListener('resize', handleResize); // Add event listener for window resize
  
      // Cleanup the event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  return (
    <>
        <section className={`grid grid-cols-1 md:flex md:justify-center md:items-center h-auto py-10 gap-10 ${isMobile ? '' : 'ml-20'} `}>
            <article className='flex flex-col items-center py-5 text-sm pb-8'>
                <h2 className='text-3xl mb-4 text-center'>TAKE ACTION</h2>
                <p className='text-center'>Become a Visionary Investor, Paving the Way <br />for the Future of Private Capital Markets</p>
                <button onClick={schedulecall} className='Konzortia-CTA-BTN text-white mx-20 my-15 p-2 mt-4 whitespace-nowrap'>Schedule a Call with our CEO & Founder today</button>
            </article>
            <div className='flex flex-col items-center'>
            <iframe
                    className='w-80 h-44' // Adjust width and height as needed
                    src="https://www.youtube.com/embed/wgPXRFzb5sg"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{width: "600px", height: "400px"}}
                    ></iframe>
                    
                <p className='text-center mt-4'>Walter Gomez - CEO & Co-Founder</p>
            </div>
        </section>
    </>
  )
}
